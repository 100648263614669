<template>
  <div class="banner">
    <div class="title-container">
      <div v-if="event == 'mosaic'">
        <h1>MOSAIC</h1>
        <h2>THIS SUNDAY 21st JANUARY</h2>
        <h3>WE'RE RUNNING OUR SUNDAY CLUB, MOSAIC!</h3>
        <h3>
          CHILDREN WHO WANT TO CAN JOIN FOR FUN, SINGING, LEARNING AND GROWING
          IN THEIR FAITH!
        </h3>
      </div>
      <div v-else-if="event == 'cafe'">
        <h1>CAFE CHURCH</h1>
        <h2>THIS SUNDAY 1st FEBUARY 10:45 AM</h2>
        <h3>ALL WECLOME AS WE GATHER TO WORSHIP TOGETHER</h3>
        <h3></h3>
      </div>
      <div v-else>
        <h1>SUNDAY SERVICE</h1>
        <h2>16th FEBUARY 10:45</h2>
        <h3></h3>
      </div>

      <!-- <div @click="changePage">
        <h3 id="emailLink">Email us for more info</h3>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "EventBanner",
  props: {
    event: String,
  },
  methods: {
    changePage() {
      this.$router.push({ path: "/getintouch" });
    },
  },
};
</script>

<style scoped>
.banner {
  max-height: 500px;
  width: 87.5vw;
  margin: 50px auto;
  height: 25vw;
  /*background-image: url("@/assets/orange-candle.png");*/
  /* background-image: url("@/assets/CandleImage.jpg"); */
  /* background-image: url("@/assets/EasterBannerImage.jpg"); */
  /* background-image: url("@/assets/MosaicImage.jpg"); */
  /* background-image: url("@/assets/CafeImage.jpg"); */
  background-image: url("@/assets/PraiseImage.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.banner .title-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #00000088;
}

.banner .title-container h1 {
  font-size: 4.5vw;
  color: white;
  margin: 10px 0;
}

.banner .title-container h2 {
  font-size: 3.5vw;
  color: white;
  margin: 10px 0;
}

.banner .title-container h3 {
  font-size: 1.8vw;
  color: white;
  margin: 8px 0;
}

@media screen and (max-width: 950px) {
  .banner {
    width: 85vw;
    height: 40vw;
    background-attachment: unset;
  }
}

@media screen and (max-width: 800px) {
  .banner .title-container h1 {
    font-size: 5.5vw;
    margin: 0;
  }

  .banner .title-container h2 {
    font-size: 4vw;
    margin: 5px;
  }

  .banner .title-container h3 {
    font-size: 2.5vw;
    margin: 5px;
  }
}

@media screen and (max-width: 600px) {
  .banner {
    width: 70vw;
    height: 70vw;
  }
  .banner .title-container h1 {
    font-size: 35px;
    margin: 0;
  }

  .banner .title-container h2 {
    font-size: 20px;
    margin: 5px;
  }

  .banner .title-container h3 {
    font-size: 14px;
    margin: 5px;
  }
}

#emailLink {
  text-decoration: underline;
  cursor: pointer;
}

#emailLink:hover {
  color: #ffffff99;
}
</style>
